import cookie from './cookie';

class Sitemap {
  constructor() {
    this.pages = [
      {
        'id' : '1.1',
        'title' : 'HOME',
        'siteSection' : 'root',
        'path' : '/home/'
      }
    ];
    this.init();
  } 

  init() {
    for (var i = 0, iMax = this.pages.length; i < iMax; i++) {
      if (window.location.pathname === this.pages[i].path || window.location.pathname === this.pages[i].path.slice(0,-1) ) {
          window.store.dispatch({ type: 'SET_CURRENT_PAGE', currentPage: this.pages[i] });
          break;
      } else {
          window.store.dispatch({ type: 'SET_CURRENT_PAGE', currentPage: { title: 'PAGE_NOT_FOUND'} });
      }        
    }
  }
}

module.exports = () => new Sitemap();