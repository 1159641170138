import { createStore } from 'redux';

import './lib/array-from.polyfill';
import './lib/array-find.polyfill';
import './lib/classlist.polyfill';

import debounce from './lib/debounce';
import ui from './reducers/ui';
import sitemap from './lib/sitemap';	
import getScript from './lib/get-script';
import throttle from './lib/throttle';
import cookie from './lib/cookie';

{
	window.store = store;

	const getParameterByName = (name) => {
		const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	};

	const getInternetExplorerVersion = () => {
		let rv = -1;

		if (navigator.appName === 'Microsoft Internet Explorer') {
			const ua = navigator.userAgent;
			const re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');

			if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
		} else if (navigator.appName === 'Netscape') {
			const ua = navigator.userAgent;
			const re = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');

			if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
		}

		return rv;
	};


	//	-----------------	//
	//	| On DOM Loaded |	//
	//	-----------------	//
	document.addEventListener('DOMContentLoaded', () => {

		let map = sitemap();

		// add IE class if needed
		const ieVersion = getInternetExplorerVersion();
		if (ieVersion !== -1) document.body.classList.add(`ie-${ieVersion}`);

		// add dev class for development
		if (getParameterByName('dev')) document.body.classList.add('dev-env');

	});
}